import { defineComponent as _defineComponent } from 'vue'
import { IGetLocationFacilityDTO } from "@/models/Facility";
import RenewableOptionsModule from "@ems/containers/RenewableOptions/RenewableOptions.module";
import TheListRECs from "@ems/containers/RenewableOptions/TheListRECs.vue";
import TheSummary from "@ems/containers/RenewableOptions/TheSummary/index.vue";
import TenantUserModule from "@ems/containers/Tenant/TenantUser.module";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import FacilityModule from "@ems/containers/Tenant/TenantFacility.module";
import GoogleMap from "@/components/GoogleMap/index.vue";
import { TypeContentMarker } from "@/components/GoogleMap/ContentMarker";
import { TypeMap } from "@/constants";
import AccountModule from "@/store/modules/Account";
import MetadataModule from "@/store/modules/Metadata";

export default /*@__PURE__*/_defineComponent({
  __name: 'BuyRECs',
  setup(__props, { expose: __expose }) {
  __expose();

const AccountRehashClient = computed(() => AccountModule.AccountClientRehash);

const isSuccess = ref(false);

const center = computed(() => TenantUserModule.dataTenantProfileGetter);
const renewAbleBuyList = computed(
  () => RenewableOptionsModule.renewableBuyListGetter
);
watch(
  () => AccountRehashClient.value,
  () => {
    RenewableOptionsModule.deleteBuyOrderItemsByOwnerId(
      AccountRehashClient.value.ExternalId
    );
  }
);
onMounted(() => {
  TenantUserModule.getTenantProfile();
  FacilityModule.getLocationFacility({
    year: `${new Date().getFullYear()}`,
  } as IGetLocationFacilityDTO);
  if (AccountRehashClient.value.Id) {
    RenewableOptionsModule.deleteBuyOrderItemsByOwnerId(
      AccountRehashClient.value.ExternalId
    );
  }
  MetadataModule.getAllCountryContinentAction();
  RenewableOptionsModule.emptyPagination();
});
onUnmounted(() => {
  RenewableOptionsModule.emptyPagination();
  RenewableOptionsModule.emptyShoppingCart();
  RenewableOptionsModule.deleteBuyOrderItemsByOwnerId(
    AccountRehashClient.value.ExternalId
  );
});

const __returned__ = { AccountRehashClient, isSuccess, center, renewAbleBuyList, get IGetLocationFacilityDTO() { return IGetLocationFacilityDTO }, get RenewableOptionsModule() { return RenewableOptionsModule }, TheListRECs, TheSummary, get TenantUserModule() { return TenantUserModule }, computed, onMounted, onUnmounted, ref, watch, get FacilityModule() { return FacilityModule }, GoogleMap, get TypeContentMarker() { return TypeContentMarker }, get TypeMap() { return TypeMap }, get AccountModule() { return AccountModule }, get MetadataModule() { return MetadataModule } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})